import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Grid,
  Stack,
  Divider,
  Typography,
  Button,
  Paper,
  TextField,
  Switch,
  Tooltip,
  IconButton,
  CardMedia,
  LinearProgress,
  Input,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InsightsIcon from "@mui/icons-material/Insights";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

import DrawerVideoAnalytics from "./DrawerVideoAnalytics";
import DrawerVideoProd from "./DrawerVideoProd";

import { useInterval } from "../../utils/UseInterval";

import { HttpMainApi } from "../../interface/main-api";

import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import PopUploadVideoViaApi from "./PopUploadVideoViaApi";

interface propsType {
  userState: userState;
}

const resolutionList: any = [720, 540, 360];

const mainApi = new HttpMainApi();

const VideoDetail = (props: propsType) => {
  const nvaigate = useNavigate();
  const { videoSeq } = useParams();
  const toastRef: any = useRef();
  const vInfoRef: any = useRef();
  const frameRef: any = useRef();
  const drawerAnalyticsRef: any = useRef();
  const drawerProdRef: any = useRef();
  const popUploadVideoRef: any = useRef();
  const attachInputRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [videoInfo, setVideoInfo] = useState<any>({});

  const [linkUrl, setLinkUrl] = useState("");
  const [code, setCode] = useState("");
  const [ebdCode, setEbdCode] = useState("");

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [displayTitle, setDisplayTitle] = useState("");
  const [displaySubTitle, setDisplaySubTitle] = useState("");
  const [mngTitle, setMngTitle] = useState("");

  const [checkedViewTitle, setCheckedViewTitle] = useState(true);
  const [checkedViewControl, setCheckedViewControl] = useState(true);
  const [checkedLikeControl, setCheckedLikeControl] = useState(true);
  const [checkedAutoPlay, setCheckedAutoPlay] = useState(true);
  const [checkedVideoLoop, setCheckedVideoLoop] = useState(false);

  const [isTranscoding, setIsTranscoding] = useState(false);
  const [maxResolution, setMaxResolution] = useState(720);

  const [mouseOver1, setMouseOver1] = useState(false);
  const [mouseOver2, setMouseOver2] = useState(false);

  const [isFileChange, setIsFileChange] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    return () => {
      setIsLoading(false);
      setVideoInfo({});
      setLinkUrl("");
      setCode("");
      setEbdCode("");
      setTitle("");
      setDesc("");
      setDisplayTitle("");
      setDisplaySubTitle("");
      setMngTitle("");
      setCheckedViewTitle(true);
      setCheckedViewControl(true);
      setCheckedAutoPlay(true);
      setCheckedVideoLoop(false);
      setIsTranscoding(true);
      setMaxResolution(720);
      setMouseOver1(false);
      setMouseOver2(false);
      setIsFileChange(false);
    };
  }, []);

  // 비디오 정보 조회
  const get_video_info = async () => {
    if (videoSeq === undefined || videoSeq === "") return;
    const param: any = {
      command: "get_video_info",
      video_key: videoSeq,
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      setVideoInfo(res.response.video_info);
      vInfoRef.current = res.response.video_info; // 이벤트 핸드러내 메소드에서는 state에 접근할 수 가 없음...
      document.title = res.response.video_info.display_title; // 브라우저 타이틀 변경
      setTitle(res.response.video_info.title);
      setDesc(res.response.video_info.video_desc !== undefined ? res.response.video_info.video_desc : "");
      setDisplayTitle(res.response.video_info.display_title !== undefined ? res.response.video_info.display_title : "");
      setDisplaySubTitle(res.response.video_info.display_sub_title !== undefined ? res.response.video_info.display_sub_title : "");
      setMngTitle(res.response.video_info.mng_title !== undefined ? res.response.video_info.mng_title : "");
      makeParam(res.response.video_info);
      setIsTranscoding(res.response.video_info.thumbnail_url === undefined || res.response.video_info.thumbnail_url === "" ? true : false);
      setMaxResolution(res.response.video_info.resolution !== undefined ? res.response.video_info.resolution : 720);
    }
  };

  useEffect(() => {
    get_video_info();
  }, []);

  // Embed, Copy Link code generate
  const makeParam = (info: any) => {
    let url = `${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${info.sk}`;
    let code = `<div style="padding:calc(100vh - 220px) 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${info.sk}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${info.title}"></iframe></div>`;
    let ebdCode = `<div style="padding:${info.video_rate} 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${info.sk}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${info.title}"></iframe></div>`;

    setLinkUrl(url);
    setCode(code);
    setEbdCode(ebdCode);
  };

  // 플레이어 옵션에 따른 queryPrameter 생성
  useEffect(() => {
    if (videoInfo.sk !== undefined) {
      let queryParam = "";
      if (!checkedViewTitle) {
        if (queryParam === "") {
          queryParam = queryParam + "noTitle";
        } else {
          queryParam = queryParam + "&noTitle";
        }
      }
      if (!checkedViewControl) {
        if (queryParam === "") {
          queryParam = queryParam + "noControl";
        } else {
          queryParam = queryParam + "&noControl";
        }
      }
      if (!checkedLikeControl) {
        if (queryParam === "") {
          queryParam = queryParam + "noLike";
        } else {
          queryParam = queryParam + "&noLike";
        }
      }
      if (!checkedAutoPlay) {
        if (queryParam === "") {
          queryParam = queryParam + "noAutoPlay";
        } else {
          queryParam = queryParam + "&noAutoPlay";
        }
      }
      if (checkedVideoLoop) {
        if (queryParam === "") {
          queryParam = queryParam + "loop";
        } else {
          queryParam = queryParam + "&loop";
        }
      }

      if (queryParam !== "") {
        queryParam = "?" + queryParam;
        let url = `${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${videoInfo.sk}${queryParam}`;
        let code = `<div style="padding:calc(100vh - 220px) 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${videoInfo.sk}${queryParam}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${videoInfo.title}"></iframe></div>`;
        let ebdCode = `<div style="padding:${videoInfo.video_rate} 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${videoInfo.sk}${queryParam}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${videoInfo.title}"></iframe></div>`;
        setLinkUrl(url);
        setCode(code);
        setEbdCode(ebdCode);
      } else {
        let url = `${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${videoInfo.sk}`;
        let code = `<div style="padding:calc(100vh - 220px) 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${videoInfo.sk}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${videoInfo.title}"></iframe></div>`;
        let ebdCode = `<div style="padding:${videoInfo.video_rate} 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${videoInfo.sk}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${videoInfo.title}"></iframe></div>`;
        setLinkUrl(url);
        setCode(code);
        setEbdCode(ebdCode);
      }
    }
  }, [checkedViewTitle, checkedViewControl, checkedLikeControl, checkedAutoPlay, checkedVideoLoop, videoInfo]);

  // 비디오 다운로드
  const fncDownloadAttach = async () => {
    setIsLoading(true);
    await fetch(videoInfo.origin_file_url, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, `${videoInfo.file_name}`);
      })
      .catch((err) => {
        console.error("err : ", err);
      });
    setIsLoading(false);
  };

  // 비디오 타이틀 수정
  const update_video_display_title = async () => {
    // 다른 프로세스에서도 데이터를 업데이트 하는 건들이 많아서 전체 put보다는 단일 update로 작성
    const param: any = {
      command: "update_video_display_title",
      video_info: {
        key: videoInfo.sk,
        display_title: displayTitle,
      },
      user_info: {
        id: props.userState.id,
      },
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_video_info();
      toastRef.current?.toast("Display title update complete", "success", 4000, { vertical: "top", horizontal: "center" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 비디오 설명 수정
  const update_video_display_sub_title = async () => {
    // 다른 프로세스에서도 데이터를 업데이트 하는 건들이 많아서 전체 put보다는 단일 update로 작성
    const param: any = {
      command: "update_video_display_sub_title",
      video_info: {
        key: videoInfo.sk,
        display_sub_title: displaySubTitle,
      },
      user_info: {
        id: props.userState.id,
      },
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_video_info();
      toastRef.current?.toast("Sub title update complete", "success", 4000, { vertical: "top", horizontal: "center" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 비디오 설명 수정
  const update_video_mng_title = async () => {
    // 다른 프로세스에서도 데이터를 업데이트 하는 건들이 많아서 전체 put보다는 단일 update로 작성
    const param: any = {
      command: "update_video_mng_title",
      video_info: {
        key: videoInfo.sk,
        mng_title: mngTitle,
      },
      user_info: {
        id: props.userState.id,
      },
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_video_info();
      toastRef.current?.toast("Mng title update complete", "success", 4000, { vertical: "top", horizontal: "center" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 비디오 삭제
  const delete_my_video = async () => {
    if (!window.confirm("비디오를 삭제합니다.\n삭제한 비디오는 복구할 수 없습니다.")) return;
    const param: any = {
      command: "delete_video",
      video_info: {
        key: videoInfo.sk,
      },
      user_info: {
        id: props.userState.id,
      },
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      nvaigate("/mngVideo");
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 사용자 썸네일 추출
  const update_video_custom_thumbnail = async (file_name: string) => {
    const param: any = {
      command: "update_video_custom_thumbnail",
      pk: vInfoRef.current.pk, // 렌더링이 되지 않아서 state를 사용 못함...
      sk: vInfoRef.current.sk,
      custom_tumbnail: file_name,
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      update_video_default_thumb("custom");
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 기본 썸네일 수정
  const update_video_default_thumb = async (flag: string) => {
    const param: any = {
      command: "update_video_default_thumb",
      pk: vInfoRef.current.pk, // 렌더링이 되지 않아서 state를 사용 못함...
      sk: vInfoRef.current.sk,
      flag: flag,
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      get_video_info();
      toastRef.current?.toast("Tumbnail update complete", "success", 4000, { vertical: "top", horizontal: "center" });
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 썸네일 추출 완료 이벤트(from iframe)
  const postMsg = (e: any) => {
    if (e.origin === process.env.REACT_APP_PLAYER_DOMAIN_NAME && e.data.msg !== undefined) {
      if (e.data.msg === "upload_custom_thumb") {
        // 썸네일 업로드 완료
        update_video_custom_thumbnail(e.data.file_name);
      }
    }
  };

  // 썸네일 업로드
  const handleThumbnailUpload = 
    async (e: ChangeEvent<HTMLInputElement> | any) => {
      const acceptableFormats = ["image/jpeg", "image/jpg", "image/png", "image/bmp", "image/gif"];
      if(e.target.files !== null) {
        const file = e.target.files[0];
        if(!acceptableFormats.includes(file.type)) {
          alert("이미지 파일만 Upload 가능합니다.");
          return;
        }

        const fileName = `snapshot/${videoSeq}/${uuidv4()}-snapshot.jpg`;
        
        // 파일 업로드
        const param: any = {
          command: "get_cf_presigned_url",
          key: fileName,
        };
        
        const res = await mainApi.post(param);
        if (res.code === "200") {
          const upload_url = res.response.upload_url;
          const uplaodRes = await fetch(upload_url, {
            method: "PUT",
            headers: { "Content-Type": "image/jpeg", acl: "public-read" },
            body: file,
          });
          if(uplaodRes.status === 200) {
            update_video_custom_thumbnail(fileName);
          }
        }
      }
    }
  

  // 동영상 파일 교체
  const change_video_file = () => {
    setIsFileChange(true);
    popUploadVideoRef.current?.open(videoInfo.sk);
  };

  useEffect(() => {
    window.addEventListener("message", postMsg);
    return () => {
      window.removeEventListener("message", postMsg);
    };
  }, []);

  const delay: number = 3000; // 3초마다 변경
  useInterval(
    () => {
      get_video_info();
    },
    isTranscoding ? delay : null
  );

  const fncUpload = () => {
    attachInputRef.current?.click();
  }

  return (
    <>
      <Box sx={{ p: 0, height: "100%", backgroundColor: "#eef1f4" }}>
        <Stack direction={"column"} spacing={0} sx={{ backgroundColor: "#eef1f4" }}>
          <Box className="video-detail-header-root">
            <Typography variant="h5" gutterBottom className="my-account-header-title">
              {videoInfo
                ? (videoInfo.display_start_date !== undefined && videoInfo.display_start_date !== "") ||
                  (videoInfo.display_end_date !== undefined && videoInfo.display_end_date !== "")
                  ? `${videoInfo.display_title} [${videoInfo.display_start_date} ~ ${videoInfo.display_end_date}]`
                  : `${videoInfo.display_title}`
                : "비디오 관리"}
            </Typography>
          </Box>
          <Divider />
          <Box className="video-detail-content-root">
            <Stack direction={"column"} spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={videoInfo.video_rate?.split("%")[0] * 1 > 100 ? 4 : 6}>
                  {!isFileChange && (
                    <>
                      <Box ref={frameRef} dangerouslySetInnerHTML={{ __html: code }} />
                      <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                        <Button variant="contained" onClick={change_video_file} endIcon={<PublishedWithChangesIcon />}>
                          {"비디오 파일 교체"}
                        </Button>
                      </Box>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} lg={videoInfo.video_rate?.split("%")[0] * 1 > 100 ? 8 : 6}>
                  <Box sx={{ pr: { xs: 0, sm: 2, xl: 4 } }}>
                    <Paper sx={{ p: 2, borderRadius: "7px" }} elevation={1}>
                      <Stack direction={"column"} spacing={2}>
                        {/* 상단 버튼 영역 */}
                        <Box className="video-detail-info-row" sx={{ overflow: "auto" }}>
                          <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "flex-start", minWidth: "120px" }}>
                              <Tooltip title="비디오 삭제">
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    // delete video
                                    delete_my_video();
                                  }}
                                  color="error"
                                  sx={{ height: "60px" }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </Tooltip>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                              <Stack direction={"row"} spacing={{ xs: 1, sm: 4 }}>
                                <Tooltip title="관련 상품">
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      drawerProdRef.current.open();
                                    }}
                                    sx={{ height: "60px" }}
                                  >
                                    <PlaylistAddIcon />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="분석">
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      drawerAnalyticsRef.current.open();
                                    }}
                                    sx={{ height: "60px" }}
                                  >
                                    <InsightsIcon />
                                  </Button>
                                </Tooltip>

                                <Tooltip title="비디오 보기(새창)">
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      // 비디오 보기
                                      window.open(linkUrl, "_blank");
                                    }}
                                    sx={{ height: "60px" }}
                                  >
                                    <OpenInNewIcon />
                                  </Button>
                                </Tooltip>

                                <CopyToClipboard
                                  text={linkUrl}
                                  onCopy={() => {
                                    toastRef.current?.toast("비디오 링크를 복사했습니다.", "success", 3000, {
                                      vertical: "top",
                                      horizontal: "center",
                                    });
                                  }}
                                >
                                  <Tooltip title="비디오 링크 복사">
                                    <Button variant="contained" sx={{ height: "60px" }}>
                                      <LinkIcon />
                                    </Button>
                                  </Tooltip>
                                </CopyToClipboard>
                                <CopyToClipboard
                                  text={ebdCode}
                                  onCopy={() => {
                                    toastRef.current?.toast("임베딩 코드를 복사했습니다.", "success", 3000, {
                                      vertical: "top",
                                      horizontal: "center",
                                    });
                                  }}
                                >
                                  <Tooltip title="임베딩 코드">
                                    <Button variant="contained" sx={{ height: "60px" }}>
                                      <ShareIcon />
                                    </Button>
                                  </Tooltip>
                                </CopyToClipboard>
                                <Tooltip title="원본 비디오 다운로드">
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      // download video
                                      fncDownloadAttach();
                                    }}
                                    sx={{ height: "60px" }}
                                  >
                                    <DownloadIcon />
                                  </Button>
                                </Tooltip>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>
                        <Divider />
                        {/* 타이틀 */}
                        <Box className="video-detail-info-row">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} lg={3} xl={2}>
                              <Typography className="video-detail-info-title">Title</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} lg={9} xl={10}>
                              <Stack direction={"row"} spacing={1}>
                                <TextField
                                  id="txtTitle"
                                  value={displayTitle}
                                  placeholder="비디오의 전시타이틀을 입력하세요."
                                  fullWidth
                                  onChange={(e) => {
                                    setDisplayTitle(e.target.value);
                                  }}
                                  size="small"
                                  type="search"
                                  autoComplete="off"
                                  inputProps={{ enterKeyHint: "Enter" }}
                                />
                                <Box>
                                  <Tooltip title="전시타이틀 저장">
                                    <IconButton
                                      onClick={() => {
                                        update_video_display_title();
                                      }}
                                    >
                                      <SaveIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                        {/* 비디오 설명 */}
                        <Box className="video-detail-info-row">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} lg={3} xl={2}>
                              <Typography className="video-detail-info-title">Sub title</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} lg={9} xl={10}>
                              <Stack direction={"row"} spacing={1}>
                                <TextField
                                  id="txtSubTitle"
                                  value={displaySubTitle}
                                  placeholder="비디오의 서브타이틀을 입력하세요."
                                  fullWidth
                                  onChange={(e) => {
                                    setDisplaySubTitle(e.target.value);
                                  }}
                                  size="small"
                                  type="search"
                                  autoComplete="off"
                                  inputProps={{ enterKeyHint: "Enter" }}
                                />
                                <Box>
                                  <Tooltip title="비디오 서브타이틀 저장">
                                    <IconButton
                                      onClick={() => {
                                        update_video_display_sub_title();
                                      }}
                                    >
                                      <SaveIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                        {/* 비디오 관리 타이틀 */}
                        <Box className="video-detail-info-row">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} lg={3} xl={2}>
                              <Typography className="video-detail-info-title">Mng title</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} lg={9} xl={10}>
                              <Stack direction={"row"} spacing={1}>
                                <TextField
                                  id="txtMngTitle"
                                  value={mngTitle}
                                  placeholder="비디오의 관리타이틀을 입력하세요."
                                  fullWidth
                                  onChange={(e) => {
                                    setMngTitle(e.target.value);
                                  }}
                                  size="small"
                                  type="search"
                                  autoComplete="off"
                                  inputProps={{ enterKeyHint: "Enter" }}
                                />
                                <Box>
                                  <Tooltip title="비디오 관리타이틀 저장">
                                    <IconButton
                                      onClick={() => {
                                        update_video_mng_title();
                                      }}
                                    >
                                      <SaveIcon color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                        {/* 썸네일 */}
                        <Box className="video-detail-info-row">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} lg={3} xl={2}>
                              <Typography className="video-detail-info-title">Thumbnail</Typography>
                            </Grid>
                            {isTranscoding ? (
                              <Grid item xs={12} sm={10} lg={9} xl={10}>
                                <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}>
                                  <Typography>비디오를 트렌스코딩 중 입니다. 완료 후 썸네일이 자동으로 추출됩니다.</Typography>
                                  <LinearProgress />
                                </Stack>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sm={10}
                                lg={9}
                                xl={10}
                                sx={{ display: "flex", justifyContent: { xs: "center", sm: "flex-start" }, overflow: "auto" }}
                              >
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                                  <Stack direction={{ xs: "column", sm: "row"}} spacing={1}>
                                    <Tooltip title="썸네일 추출">
                                      <Button
                                        variant="contained"
                                        sx={{ height: "90px", width: "80px", fontSize: "24px !important" }}
                                        color="info"
                                        onClick={() => {
                                          let code = `<div style="padding:calc(100vh - 220px) 0 0 0;position:relative;"><iframe src="${process.env.REACT_APP_PLAYER_DOMAIN_NAME}/video/${videoInfo.sk}?thumb" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="${videoInfo.title}"></iframe></div>`;
                                          setCode(code);
                                          window.scrollTo({ top: 0, behavior: "smooth" });
                                        }}
                                      >
                                        +
                                      </Button>
                                    </Tooltip>
                                    <Input id="thumbnailUplaod"
                                      key="attachment"
                                      type="file"
                                      inputProps={{ multiple: true, accept: "image/*"}}
                                      inputRef={attachInputRef}
                                      onChange={handleThumbnailUpload}
                                      sx={{ display: "none"}}/>
                                    <Tooltip title="썸네일 업로드">
                                      <Button
                                        variant="contained"
                                        sx={{ height: "90px", width: "80px", fontSize: "24px !important", 
                                          backgroundColor: "orange",
                                        "&:focus": {
                                          backgroundColor: "#db620b"
                                        },
                                        "&:hover": {
                                          backgroundColor: "#db620b", 
                                        },
                                        "&:active": {
                                          backgroundColor: "#db620b",
                                        },}}
                                        color="info"
                                        onClick={() => {
                                          fncUpload();
                                        }}>
                                        ↑
                                      </Button>
                                    </Tooltip>
                                  </Stack>
                                  {videoInfo.thumbnail_url !== undefined && (
                                    <Tooltip title="자동 썸네일">
                                      <Button
                                        onClick={() => {
                                          update_video_default_thumb("video");
                                        }}
                                        sx={{ p: 0 }}
                                      >
                                        <CardMedia
                                          component="img"
                                          image={videoInfo.thumbnail_url}
                                          onMouseEnter={(e) => {
                                            setMouseOver1(true);
                                          }}
                                          onMouseLeave={(e) => {
                                            setMouseOver1(false);
                                          }}
                                          sx={{
                                            width: "160px",
                                            objectFit: "fit",
                                            borderRadius: "4px",
                                            ...(mouseOver1 ? { height: `calc(160px * ${videoInfo.video_rate})` } : { height: "90px" }),
                                            ...((videoInfo.video_thumb === undefined || videoInfo.video_thumb === "video") && {
                                              border: "2px solid #ff5900",
                                              boxSizing: "border-box",
                                            }),
                                          }}
                                        />
                                      </Button>
                                    </Tooltip>
                                  )}
                                  {videoInfo.custom_tumbnail !== undefined && (
                                    <Tooltip title="사용자 지정 썸네일">
                                      <Button
                                        onClick={() => {
                                          update_video_default_thumb("custom");
                                        }}
                                        sx={{ p: 0 }}
                                      >
                                        <CardMedia
                                          component="img"
                                          image={videoInfo.custom_tumbnail}
                                          onMouseEnter={(e) => {
                                            setMouseOver2(true);
                                          }}
                                          onMouseLeave={(e) => {
                                            setMouseOver2(false);
                                          }}
                                          sx={{
                                            width: "160px",
                                            objectFit: "fit",
                                            borderRadius: "4px",
                                            ...(mouseOver2 ? { height: `calc(160px * ${videoInfo.video_rate})` } : { height: "90px" }),
                                            ...(videoInfo.video_thumb === "custom" && {
                                              border: "2px solid #ff5900",
                                              boxSizing: "border-box",
                                            }),
                                          }}
                                        />
                                      </Button>
                                    </Tooltip>
                                  )}
                                </Stack>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                        <Divider />
                        {/* 플레이어 설정 */}
                        <Box className="video-detail-info-row">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={2} lg={3} xl={2}>
                              <Typography className="video-detail-info-title">Player</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} lg={9} xl={10}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} md={12} lg={4} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                  <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                      <Typography className="video-detail-embed-title">Title</Typography>
                                      <Tooltip title="플레이어 타이틀(노출/해제)">
                                        <Switch
                                          checked={checkedViewTitle}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setCheckedViewTitle(e.target.checked);
                                          }}
                                          inputProps={{ "aria-label": "controlled" }}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                      <Typography className="video-detail-embed-title">Control</Typography>
                                      <Tooltip title="플레이어 컨트롤(노출/해제)">
                                        <Switch
                                          checked={checkedViewControl}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setCheckedViewControl(e.target.checked);
                                          }}
                                          inputProps={{ "aria-label": "controlled" }}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                      <Typography className="video-detail-embed-title">Like</Typography>
                                      <Tooltip title="플레이어 좋아요(노출/해제)">
                                        <Switch
                                          checked={checkedLikeControl}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setCheckedLikeControl(e.target.checked);
                                          }}
                                          inputProps={{ "aria-label": "controlled" }}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                      <Typography className="video-detail-embed-title">Auto Play</Typography>
                                      <Tooltip title="플레이어 자동재생(설정/해제)">
                                        <Switch
                                          checked={checkedAutoPlay}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setCheckedAutoPlay(e.target.checked);
                                          }}
                                          inputProps={{ "aria-label": "controlled" }}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                      <Typography className="video-detail-embed-title">Loop</Typography>
                                      <Tooltip title="플레이어 반복재생(설정/해제)">
                                        <Switch
                                          checked={checkedVideoLoop}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setCheckedVideoLoop(e.target.checked);
                                          }}
                                          inputProps={{ "aria-label": "controlled" }}
                                        />
                                      </Tooltip>
                                    </Box>
                                  </Stack>
                                </Grid>
                                <Grid item xs={12} sm={8} md={12} lg={8} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                  <Box sx={{ width: "100%" }}>
                                    <Stack direction={"column"} spacing={2}>
                                      <TextField
                                        id="txtEmbed"
                                        value={ebdCode}
                                        fullWidth
                                        multiline
                                        type="search"
                                        minRows={9.5}
                                        autoComplete="off"
                                        sx={{ wordBreak: "break-all" }}
                                        inputProps={{ enterKeyHint: "Enter" }}
                                      />
                                    </Stack>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                        {/* 링크 */}
                        {(videoInfo.playback_url !== undefined || videoInfo.hls_url !== undefined) && (
                          <>
                            <Divider />
                            <Box className="video-detail-info-row">
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={2} lg={3} xl={2}>
                                  <Typography className="video-detail-info-title">HLS</Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} lg={9} xl={10}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                      <CopyToClipboard
                                        text={videoInfo.hls_url !== undefined ? videoInfo.hls_url : videoInfo.playback_url}
                                        onCopy={() => {
                                          toastRef.current?.toast("HLS 링크(자동속도)를 복사했습니다.", "success", 3000, {
                                            vertical: "top",
                                            horizontal: "center",
                                          });
                                        }}
                                      >
                                        <Tooltip title={"자동속도 주소 복사"}>
                                          <Button variant="contained" fullWidth endIcon={<ContentCopyIcon />}>
                                            {"auto"}
                                          </Button>
                                        </Tooltip>
                                      </CopyToClipboard>
                                    </Grid>
                                    {resolutionList.map(
                                      (resolution: number, index: number) =>
                                        resolution <= maxResolution && (
                                          <Grid
                                            key={`resol-${index}`}
                                            item
                                            xs={12}
                                            sm={4}
                                            sx={{ display: "flex", justifyContent: "flex-start" }}
                                          >
                                            <CopyToClipboard
                                              text={
                                                videoInfo.hls_url !== undefined
                                                  ? videoInfo.hls_url.split(".m3u8")[0] + "_" + resolution + ".m3u8"
                                                  : videoInfo.playback_url.split(".m3u8")[0] + "_" + resolution + ".m3u8"
                                              }
                                              onCopy={() => {
                                                toastRef.current?.toast("HLS 링크(자동속도)를 복사했습니다.", "success", 3000, {
                                                  vertical: "top",
                                                  horizontal: "center",
                                                });
                                              }}
                                            >
                                              <Tooltip title={resolution + "p 주소 복사"}>
                                                <Button variant="contained" fullWidth endIcon={<ContentCopyIcon />}>
                                                  {resolution + "p"}
                                                </Button>
                                              </Tooltip>
                                            </CopyToClipboard>
                                          </Grid>
                                        )
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </>
                        )}
                      </Stack>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <PopUploadVideoViaApi
        ref={popUploadVideoRef}
        userState={props.userState}
        callback={(payload: any) => {
          if (payload.command === "upload_finish") {
            setIsFileChange(false);
            get_video_info();
          } else if (payload.command === "close") {
            setIsFileChange(false);
          }
        }}
      />
      {videoInfo.sk !== undefined && (
        <>
          <DrawerVideoAnalytics ref={drawerAnalyticsRef} userState={props.userState} videoInfo={videoInfo} callback={() => {}} />
          <DrawerVideoProd
            ref={drawerProdRef}
            userState={props.userState}
            videoInfo={videoInfo}
            callback={(payload: any) => {
              if (payload.command === "add_prod_finish") {
                get_video_info();
                toastRef.current?.toast("관련 상품 등록 완료", "success", 4000, { vertical: "top", horizontal: "center" });
              } else if (payload.command === "delete_prod_finish") {
                get_video_info();
                toastRef.current?.toast("선택한 상품 삭제 완료", "success", 4000, { vertical: "top", horizontal: "center" });
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default VideoDetail;
